<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form inline size="small">
					<el-form-item :label="$t('teacher.formItem.class')">
						<el-input v-model="screenForm.name" clearable :placeholder="$t('teacher.placeholder.className')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-table">
				<el-table :data="pageList" size="small">
					<el-table-column :label="$t('teacher.tableHead.class')" prop="name"></el-table-column>
					<!-- <el-table-column :label="$t('teacher.tableHead.classStatus')">
						<template slot-scope="scope">{{scope.row.state==1?$t('teacher.tags.open'):$t('teacher.tags.close')}}</template>
					</el-table-column> -->
					<el-table-column :label="$t('teacher.tableHead.paymentReal')">
						<template slot-scope="scope">
							<el-switch v-model="scope.row.open_alipay" :active-value="1" :inactive-value="0" :disabled="scope.row.open_alipay==1" @change="handleChange($event,scope)"></el-switch>
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.paymentStatus')">
						<template slot-scope="scope">{{scope.row.open_alipay==1?$t('teacher.tags.open'):$t('teacher.tags.close')}}</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="t-page-bottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('teacher.pagination.prevText')" :next-text="$t('teacher.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { getClassList, uploadPayment } from '@/api/teacher';
	export default {
		data() {
			return {
				screenForm: {
					name: ''
				},
				pageList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
				value1: 0
			}
		},
		mounted() {
			this.getPageList();
		},
		methods: {
			getPageList() {
				let params = {
					...this.screenForm,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				getClassList(params).then(response => {
					this.pageList = response.data.list;
					this.pageTotal = parseInt(response.data.count);
				})
			},
			handleSearch() {
				this.pageCurr = 1;
				this.getPageList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getPageList();
			},
			handleChange(val,{ row }) {
				this.$confirm(this.$t('seller.confirm.openAlipay'),'',{
					confirmButtonText: this.$t('seller.actions.continue'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					const params = {
						class_id: row.class_id,
						open_alipay: row.open_alipay
					}
					uploadPayment(params).then(()=> {
					}).catch(()=> {
						this.getPageList()
					})
				}).catch(() => {
					this.getPageList()
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;
		.t-page-title {
			padding-bottom: 20px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.t-page-screen {
			// padding-top: 18px;
			// border-top: 1px solid #EBEEF5;
		}

		.t-page-table {
			::v-deep .el-table {
				th.el-table__cell {
					background-color: $--seller-thead-background-1;
				}
			}
		}

		.t-page-bottom {
			padding: 15px 0;
			text-align: center;
		}
	}
</style>
